@import '../const/index.less';

@transition-duration: 0.5s;


.@{clsPrefix}-header-announcement-fixed {
  display: flex;
  // background-image: linear-gradient(to right, #a8edea 0%, #fed6e3 100%);
  height: 64px;
  text-align: center;
  line-height: 64px;
  background-color: rgba(17, 17, 17, .75);
  justify-content: space-around;
  align-items: center;
  color:#ccc;
  font-size: 17px;
  letter-spacing: 2px;
  .@{clsPrefix}-header-logo {
    width: 32px;
    height: 32px;
    background-image: url('../asset/heimiao.png');
    background-position: center center;
    background-size: cover;
    display: inline-block;
    border-radius: 10px;
  }
}

@media (min-width: 1600px) {
  .@{clsPrefix}-header-announcement-fixed {
    font-size: 25px;
  }
  .@{clsPrefix}-header-announcement-logo {
    font-size: 20px !important;
  }
  .@{clsPrefix}-header-announcement {
    span {
      font-size: 22px !important;
    }
    pre {
      font-size: 18px !important;
    }
    .ant-collapse-expand-icon {
      height: 28px !important;
      img {
        width: 28px !important;
        height: 28px !important;
      }
    }
    
  }
}
.@{clsPrefix}-header-announcement-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 15px;
}
.@{clsPrefix}-header-announcement {
  opacity: 0;
  transform: translateY(-100%);
  transition: opacity @transition-duration ease, transform @transition-duration ease;
  background-image: url('../asset/announce_bg.jpg');
  background-position: center;
  background-size: cover;
  border-radius: 20px;
  padding: 0 20px;
  min-height: 400px;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 20px;
    z-index: 1;
  }

  .ant-collapse {
    max-height: 500px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(26, 26, 26);
      box-shadow : inset 0 0 5px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      background : #fdf2e0;
      opacity: 0.5;
    }
  }

  .ant-collapse-header-text,
  pre,
  .ant-collapse-expand-icon,
  * {
    position: relative;
    z-index: 2;
  }

  .ant-collapse-header-text {
    color: #f4f4f4;
  }
  pre {
    color: #f4f4f4;
  }
  .ant-collapse-expand-icon {
    color: white;
  }
}
/* 向下滑入 */
.slide-down {
  opacity: 1;
  transform: translateY(0);  // 元素完全显示
}

/* 向上滑出 */
.slide-up {
  opacity: 0;
  transform: translateY(-200%);  // 元素上移并滑出
}