@import '../const/index.less';

@--color-players-full: #e16c96;
@--color-players-half: #fcd337;
@--color-players-less: #66c18c;

@media (min-width: 1600px) {
  .@{clsPrefix}-server-list-item {
    font-size: 20px !important;
    .@{clsPrefix}-server-players {
      margin: 0 20px;
    }
    .@{clsPrefix}-server-map {
      &-name {
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          display: inline-block;
          height: 30px !important;
          font-size: 18px !important;
          line-height: 30px;
        }
      }
      &-code {
        white-space: nowrap;
      }
    }
    .@{clsPrefix}-server-join {
      button > span {
        font-size: 20px !important;
        &:hover {
          font-size: 20px !important;
        }
      }
    }
  }
}

.@{clsPrefix}-server-list-item {
  width: 100%;
  display: flex;
  align-items: center;
  color: #ccc;
  font-size: 14px;
  .full {
    color: @--color-players-full;
  }
  .half {
    color: @--color-players-half;
  }
  .less {
    color: @--color-players-less;
  }

  .@{clsPrefix}-server-name {
    white-space: nowrap;
  }

  .@{clsPrefix}-server-players {
    text-align: center;
    width: 40px;
  }

  .@{clsPrefix}-server-map {
    max-width: 200px;
    padding: 0 5px;
    display: flex;
    &-name {
      font-size: 15px;
    }
    &-tag {
      font-size: 14px;
      color: #ffe96d;
      border: 1px solid rgb(255, 85, 0);
      background-color: transparent;
      &.official {
        color: #ffe96d;
        background-color: rgb(255, 85, 0);
      }
    }
  }

  .@{clsPrefix}-server-map.mobile {
    flex-direction: column;
  }

  @media (max-width: 600px) {
    .@{clsPrefix}-server-join {
      display: none;
    }
  }

  .@{clsPrefix}-server-join {
    margin-left: auto;
    button {
      // background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%);
      background-color: #2d2f33;
      color: #f4d8a8;
      font-size: 13px;
      &:hover {
        background-color: #2d2f33 !important;
        color: #f4d8a8 !important;
        font-size: 13px;
      }
    }
  }
}
