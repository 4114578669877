@import '../const/index.less';

.@{clsPrefix}-server-list-detail {
  .ant-table {
    background-color: rgba(0, 0, 0, 0.75) !important;
    opacity: 0.65;
    color: white !important;
    padding: 10px;
    @media (min-width: 1600px) {
      .ant-table-content {
        table {
         font-size: 20px !important;
        }
      }
    }
  }

   table {
    font-size: 14px !important;
    color: white !important;
    border-collapse: collapse;
    .ant-table-thead >tr>th {
      color: white !important;
      background: transparent !important;
      opacity: 0.85;
    }
    .ant-table-tbody >tr >td {
      border: none !important;
    }
    .highlight {
      color: #ff4d4f !important;
    }
    tr:has(.highlight) {
      border: 1px solid white;
    }
  }
  .ant-table-cell-row-hover {
    background-color: transparent !important; 
  }
}