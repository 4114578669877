@import '../../const/index.less';
@text-color: #ffffff;
@badge-color: #3891c5;
@font-weight-bold: bold;

@media (min-width: 1600px) {
  .@{clsPrefix}-user-card {
    width: 450px !important;
    span, div {
      font-size: 18px;
    }
  }
}

.@{clsPrefix}-user-card {
  position: relative;
  width: 370px;
  padding: 20px;
  margin-top: 10px;
  border-radius: 8px;
  border: 1px solid #f4d8a8;
  color: #ccc;
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-around;

    @media (min-width: 1600px) {
      .username {
        font-size: 20px !important;
      }
    }

    .username {
      font-size: 16px;
    }
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: fade(@text-color, 10%);
    margin: 10px 0;
  }
  span {
    // margin-left: 10px;s
    // color: @text-color;
    // font-weight: @font-weight-bold;
  }
  div > span {
    margin-left: 10px;
  }
}

.@{clsPrefix}-user-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../../asset/profile.jpeg');
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  z-index: -1;
}