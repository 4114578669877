@import '../const/index.less';

.@{clsPrefix}-server {
  display: flex;
}

@media (max-width: 1220px) {
  .@{clsPrefix}-server-menu {
    display: none;
  }
  .@{clsPrefix}-server-list {
    border-radius: 10px !important;
  }
}

  @media (min-width: 1600px) {
    .@{clsPrefix}-server-menu {
      font-size: 18px !important;
    }
  }

.@{clsPrefix}-server-menu {
  max-width: 200px;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 10px 0px 0px 10px;
  margin-left: 30px;
  opacity: 0.85;
  height: fit-content;
  .@{clsPrefix}-icon {
    margin: 0 10px;
  }
  .ant-menu-title-content {
    color: #ccc;
  }
  .ant-menu-item.ant-menu-item-selected {
    background-color: rgb(70, 70, 70);
    // background-image: linear-gradient(to top, #fad0c4 0%, #ffd1ff 100%);
  }
}

.@{clsPrefix}-server-list {
  flex: 1;
  overflow-x: hidden;
  &-content {
    width: 100%;
  }
  max-height: 82vh;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.75);
  opacity: 0.85;
  padding-top: 10px;
  max-width: 1000px;
  border-radius: 0 10px 10px 10px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(26, 26, 26);
    box-shadow : inset 0 0 5px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background : #fdf2e0;
    opacity: 0.5;
  }
}

